.form-wrapper {
	height: 100%;
	.sd-element--with-frame {
		box-shadow: 0 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
	}
	.sv-components-column {
		align-items: center;
	}
	// Sahahbaaz: fixed dropdown selection height
	.sv-tagbox__item {
		height: 40px;
	}
	.sd-page {
		padding: 10px 2px 2px !important;
		margin-bottom: 40px !important;
	}

	.sd-body.sd-body--static {
		max-width: 100% !important;
	}
	.sv-components-row {
		background: #fff !important;
	}
	--sjs-primary-backcolor: var(
		--color-primary-light
	); /* Change this to your desired accent color */
	--primary: var(--color-primary-light);
}

/* Main wrapper for the form */
.form-wrapper_v2 {
	/* Adjusts the margin for rows within the .sd-row class */
	.sd-row {
		margin-top: 12px !important; /* Adds spacing between rows */
	}

	/* Styles for the question header when located at the top */
	.sd-question__header--location-top {
		padding-bottom: 4px !important; /* Adds space below the header */
	}
}

.sd-completedpage {
	// min-height: 500px;
	color: var(--color-primary);
	display: flex;
	justify-content: center;
	align-items: center;
}
.sd-completedpage:before,
.sd-completedpage:after {
	content: unset;
}
//this is temp change so commnet that
// .sd-title > .sv-string-viewer {
// 	color: var(--color-primary);
// }
.sd-btn.sd-btn--action.sd-navigation__complete-btn {
	background-color: var(--color-primary-light);
	&:hover {
		background-color: var(--color-primary-light);
	}
}
.sd-title.sd-container-modern__title {
	box-shadow: 0px 2px 0px var(--primary, var(--color-primary));
}
.sd-input {
	&:focus {
		box-shadow: 0 0 0 2px var(--primary, var(--color-primary-light));
	}
}

.survey_loader {
	height: 300px;
	display: flex;
	justify-content: center;
	margin-top: -82px;
	background: #f3f3f3;
	padding-top: 36px;
}

.terms_header_container--loader {
	height: 400px;
}

.sd-container-modern {
	margin-bottom: 0 !important;
}

.sd-body.sd-body--static {
	padding-top: 0 !important;
	@media screen and (max-width: 768px) {
		padding: 0 !important;
		padding-bottom: 10px;
	}
}

.sd-navigation__next-btn {
	background-color: var(--color-primary-light) !important;
}
// #sv-nav-next,
// #sv-nav-complete {
// 	position: fixed !important;
// 	bottom: 0;
// 	padding-block: 10px;
// 	width: 100%;
// 	padding-right: 40px;
// 	background-color: var(--white);
// }
.sd-body.sd-body--static .sd-body__navigation.sd-action-bar {
	padding-left: 0 !important;
}

.sd-navigation__next-btn,
.sd-navigation__preview-btn {
	background-color: var(--color-primary-light) !important;
	color: var(--white) !important;
	&:hover {
		background-color: var(--color-primary-light) !important;
	}
}

.sd-progress-buttons--numbered
	.sd-progress-buttons__list
	li
	.sd-progress-buttons__button
	.sd-progress-buttons__button-background {
	height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
	width: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sd-progress-buttons--numbered
	.sd-progress-buttons__list
	li
	.sd-progress-buttons__button {
	width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
	height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sd-progress-buttons--numbered
	.sd-progress-buttons__list
	li:not(:first-child)
	> .sd-progress-buttons__connector {
	bottom: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-components-column.sv-components-container-center {
	width: 100%;
	.sd-progress-buttons__container-center {
		width: 100%;
	}
}

.sd-title .sv-title-actions {
	width: 100% !important;
}

.sd-progress-buttons__button > span {
	font-size: 16px;
}

.CounteryStateDropdown__container .input__label:first-of-type {
	display: none !important;
} /* Survey description font */
.form-wrapper span,
.form-wrapper input,
.form-wrapper textarea {
	font-family: 'Ivar Display', 'Source Sans Pro', 'Helvetica Neue', Helvetica,
		Arial, sans-serif !important;
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 500;
}

.sd-title.sd-question__title span {
	font-weight: 600;
}

/* Question title font */
.sd-title.sd-page__title span {
	font-family: 'Ivar Display', serif !important;
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.1;
}

.sd-description.sd-page__description span {
	color: #373633;
}

.sd-title {
	display: flex;
	align-items: center;
}

.go-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: var(--color-primary-light) !important;
	border: 1px solid var(--color-primary-light) !important;
	border-radius: 50% !important;
	color: #fff;
	padding: 10px;
	opacity: 0.7;
	transition: opacity 0.2s ease-in-out !important;
	z-index: 111111111;
}

.go-to-top:hover {
	opacity: 1;
}

.go-to-bottom {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: var(--color-primary-light) !important;
	border: 1px solid var(--color-primary-light) !important;
	color: #fff;
	border-radius: 50% !important;
	padding: 10px;
	opacity: 0.7;
	transition: opacity 0.2s ease-in-out !important;
	z-index: 111111111;
}

.go-to-bottom:hover {
	opacity: 1;
}
